import axios from '@/plugins/axios';
import {
    AnalysisProgressFetchResponse,
    IAnalysisProgressRepository,
} from '@/models/interface/EmotionAnalysis/IAnalysisProgressRepository';

export class AnalysisProgressRepository implements IAnalysisProgressRepository {
    async getAnalysisProgressIndex(tenantIdentifier: string): Promise<AnalysisProgressFetchResponse> {
        return await axios
            .get('/api/analysis-progress/index', {
                params: {
                    tenantIdentifier: tenantIdentifier,
                },
            })
            .catch(error => error.response || error);
    }
}
