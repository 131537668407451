




























import { useAuthenticationInfo } from '@/composables/store/Auth/useAuthenticationInfo';
import { useRouter } from '@/composables/function/Common/useRouter';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    setup() {
        const router = useRouter();

        const { isAuthenticated, initAuthenticationInfo } = useAuthenticationInfo();

        const logout = async (): Promise<void> => {
            await initAuthenticationInfo();
            router.push({ name: 'Login' });
        };

        return {
            isAuthenticated,
            logout,
        };
    },
});
