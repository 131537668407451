import axios from '@/plugins/axios';
import {
    AnalysisFetchResponse,
    AnalysisRequestResponse,
    IEmotionAnalysisRepository,
} from '@/models/interface/EmotionAnalysis/IEmotionAnalysisRepository';

export class EmotionAnalysisRepository implements IEmotionAnalysisRepository {
    async postAnalysisImplement(request: FormData): Promise<AnalysisRequestResponse> {
        return await axios.post('/api/analysis', request).catch(error => error.response || error);
    }

    async getAnalysisIndex(
        tenantIdentifier: string,
        pageSize: number,
        pageNumber: number,
        opName: string | undefined,
        statusList: string[],
    ): Promise<AnalysisFetchResponse> {
        return await axios
            .get(`/api/emotion-analysis/index/${pageSize}/${pageNumber}`, {
                params: {
                    tenantIdentifier: tenantIdentifier,
                    opName: opName,
                    statusList: statusList,
                },
            })
            .catch(error => error.response || error);
    }
}
