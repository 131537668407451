import {
    ITenantMRepository,
    TenantCreationResponse,
    TenantDeleteResponse,
    TenantDetailFetchResponse,
    TenantFetchForSimpleLoginResponse,
    TenantFetchResponse,
    TenantRestoreResponse,
} from '@/models/interface/Management/ITenantMRepository';
import axios from '@/plugins/axios';
import { RestoreRequestForm } from '@/types/restore';

export class TenantMRepository implements ITenantMRepository {
    async postTenantCreation(
        companyId: number,
        tenantName: string,
        tenantIdentifier: string,
        statsDomainAddressId: number,
    ): Promise<TenantCreationResponse> {
        return await axios
            .post('/api/management/tenant', {
                companyId: companyId,
                tenantName: tenantName,
                tenantIdentifier: tenantIdentifier,
                statsDomainAddressId: statsDomainAddressId,
            })
            .catch(error => error.response || error);
    }

    async getTenantIndexWithSystem(companyId: number): Promise<TenantFetchResponse> {
        return await axios.get(`/api/management/tenants/${companyId}`).catch(error => error.response || error);
    }

    async deleteTenant(tenantIds: number[]): Promise<TenantDeleteResponse> {
        return await axios.post('/api/management/tenants', { ids: tenantIds }).catch(error => error.response || error);
    }

    async getTenantDetail(tenantId: number): Promise<TenantDetailFetchResponse> {
        return await axios.get(`/api/management/tenant/${tenantId}`).catch(error => error.response || error);
    }

    async getTenantIndexForSimpleLogin(companyId: number): Promise<TenantFetchForSimpleLoginResponse> {
        return await axios
            .get('/api/management/tenants', {
                params: {
                    companyId: companyId,
                },
            })
            .catch(error => error.response || error);
    }

    async restoreTenants(params: RestoreRequestForm): Promise<TenantRestoreResponse> {
        return await axios.post('/api/restore', params).catch(error => error.response || error);
    }
}
