import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: { name: 'Login' },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/components/Auth/environments/Login.vue'),
        beforeEnter: (to, from, next) => {
            const isAuthenticated = !!localStorage.getItem('isAuthenticated');

            if (isAuthenticated) next({ name: 'CompanyList' });
            else next();
        },
    },
    {
        path: '/emotion-analysis',
        name: 'EmotionAnalysis',
        component: () => import('@/components/EmotionAnalysis/environments/Analysis.vue'),
    },
    {
        path: '/management',
        component: () => import('@/components/Management/environments/Management.vue'),
        children: [
            {
                path: '/management',
                name: 'CompanyList',
                component: () => import('@/components/Management/ecosystems/CompanyList.vue'),
            },
            {
                path: 'company-detail',
                name: 'CompanyDetail',
                component: () => import('@/components/Management/ecosystems/CompanyDetail.vue'),
            },
            {
                path: 'registration',
                name: 'CompanyRegistration',
                component: () => import('@/components/Management/organisms/CompanyRegistrationForm.vue'),
            },
            {
                path: 'registration/tenant-creation/:id',
                name: 'TenantCreation',
                component: () => import('@/components/Management/organisms/TenantCreationForm.vue'),
                // NOTE: ナビゲーションガードはもっといい方法がありそう…。
                beforeEnter: (to, from, next) => {
                    if (
                        !parseInt(to.params.id) ||
                        (from.name !== 'CompanyRegistration' && from.name !== 'CompanyList')
                    ) {
                        next({ name: 'CompanyList' });
                    } else {
                        next();
                    }
                },
            },
            {
                path: 'reflect-schedules',
                name: 'ReflectScheduleList',
                component: () => import('@/components/Management/organisms/ReflectScheduleList.vue'),
            },
            {
                path: 'company-delete',
                name: 'CompanyDelete',
                component: () => import('@/components/Management/organisms/CompanyDelete.vue'),
            },
            {
                path: 'tenant-delete',
                name: 'TenantDelete',
                component: () => import('@/components/Management/organisms/TenantDelete.vue'),
            },
            {
                path: 'tenant-detail/:tenantId',
                name: 'TenantDetail',
                component: () => import('@/components/Management/ecosystems/TenantDetail.vue'),
            },
            {
                path: 'plan-management',
                name: 'PlanManagement',
                component: () => import('@/components/Management/organisms/AnalysisPlanList.vue'),
            },
            {
                path: 'company-plan',
                name: 'CompanyPlan',
                component: () => import('@/components/Management/organisms/CompanyPlan.vue'),
                beforeEnter: (to, from, next) => {
                    if (!to.params.id || (from.name !== 'TenantCreation' && from.name !== 'CompanyDetail')) {
                        next({
                            name: 'CompanyDetail',
                        });
                    } else {
                        next();
                    }
                },
            },
            {
                path: 'usage-bill',
                name: 'UsageBill',
                component: () => import('@/components/Management/ecosystems/UsageBill.vue'),
            },
            {
                path: 'restore',
                name: 'Restore',
                component: () => import('@/components/Management/environments/Restore.vue'),
            },
            {
                path: 'login',
                name: 'SimpleLogin',
                component: () => import('@/components/Management/ecosystems/SimpleLogin.vue'),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('isAuthenticated');

    if (to.name !== 'Login' && !isAuthenticated) {
        next({ name: 'Login' });
    } else {
        next();
    }
});

export default router;
