import { IPaymentRepository, PaymentCompanyFetchResponse } from '@/models/interface/Management/IPaymentRepository';
import axios from '@/plugins/axios';

export class PaymentRepository implements IPaymentRepository {
    async getPaymentCsv(startDate: string, endDate: string): Promise<void> {
        return await axios
            .get('/api/payment/companies-bill', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                },
                responseType: 'blob',
            })
            /** CSVのダウンロード処理 **/
            .then(response => {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = this.getFileName(response.headers['content-disposition']);
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(error => error.response || error);
    }

    async getPaymentCompanyIndex(startDate: string, endDate: string): Promise<PaymentCompanyFetchResponse> {
        return await axios
            .get('/api/payment/company', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                },
            })
            .catch(error => error.response || error);
    }

    /**
     * CSVのファイル名を取得
     *
     * @param contentDisposition
     * @returns string
     */
    getFileName = (contentDisposition: string): string => {
        return decodeURI(contentDisposition).substring(contentDisposition.indexOf("''") + 2, contentDisposition.length);
    };
}
