import {
    AnalysisPlanContractResponse,
    AnalysisPlanCreationResponse,
    AnalysisPlanDeleteResponse,
    AnalysisPlanFetchResponse,
    CUSTOM_PLAN_KIND,
    IAnalysisPlanRepository,
    STANDARD_PLAN_KIND,
} from '@/models/interface/Management/IAnalysisPlanRepository';
import axios from '@/plugins/axios';

export class AnalysisPlanRepository implements IAnalysisPlanRepository {
    async postAnalysisPlanCreation(
        name: string,
        time: number,
        additionalFee: number,
        standardPlanFlag: boolean,
        memo?: string,
    ): Promise<AnalysisPlanCreationResponse> {
        return await axios
            .post('/api/management/analysis-plan', {
                name: name,
                time: time,
                additionalFee: additionalFee,
                memo: memo,
                kind: standardPlanFlag ? STANDARD_PLAN_KIND : CUSTOM_PLAN_KIND,
            })
            .catch(error => error.response || error);
    }

    async postAnalysisPlanDelete(planId: number): Promise<AnalysisPlanDeleteResponse> {
        return await axios.delete(`/api/management/analysis-plan/${planId}`).catch(error => error.response || error);
    }

    async getAnalysisPlanIndex(): Promise<AnalysisPlanFetchResponse> {
        return await axios.get('/api/management/analysis-plan/index').catch(error => error.response || error);
    }

    async postAnalysisPlanCompanyContract(
        companyId: number,
        planId: number,
        applyDate: string,
        startDate: string,
        memo?: string,
    ): Promise<AnalysisPlanContractResponse> {
        return await axios
            .post('/api/management/contract', {
                companyId: companyId,
                planId: planId,
                applyDate: applyDate,
                startDate: startDate,
                memo: memo ? memo : undefined,
            })
            .catch(error => error.response || error);
    }
}
