import axios from '@/plugins/axios';
import { FailedResponse } from '../../interface/common';
import {
    ContractScheduleCancelResponse,
    CompanyDeleteResponse,
    CompanyDetailResponse,
    CompanyRegistrationResponse,
    DeletableCompanyFetchResponse,
    ICompanyRepository,
} from '../../interface/Management/ICompanyRepository';

export class CompanyRepository implements ICompanyRepository {
    async postCompanyRegistration(request: FormData): Promise<CompanyRegistrationResponse> {
        return await axios.post('/api/management/company', request).catch(error => error.response || error);
    }

    async getCompanyIndex(name?: string, planId?: number): Promise<FailedResponse> {
        return await axios
            .get(`/api/management/companies`, {
                params: {
                    name: name,
                    planId: planId,
                },
            })
            .catch(error => error.response || error);
    }

    async getDeletableCompanyIndex(): Promise<DeletableCompanyFetchResponse> {
        return await axios.get('/api/management/deletable-companies').catch(error => error.response || error);
    }

    async deleteCompany(ids: number[]): Promise<CompanyDeleteResponse> {
        return await axios
            .post('/api/management/schedule/company', {
                ids: ids,
                type: 'delete',
            })
            .catch(error => error.response || error);
    }

    async getCompanyDetail(id: number): Promise<CompanyDetailResponse> {
        return await axios.get(`/api/management/company-detail/${id}`).catch(error => error.response || error);
    }

    async contractScheduleCancel(contractId: number): Promise<ContractScheduleCancelResponse> {
        return await axios.delete(`/api/management/contract/${contractId}`).catch(error => error.response || error);
    }

    async postAutoStopSettingUpdate(companyId: number, autoStop: boolean): Promise<CompanyRegistrationResponse> {
        return await axios.post(`/api/management/company/auto-stop-setting/${companyId}`, {
            autoStop: autoStop,
        });
    }
}
