









import { defineComponent } from '@vue/composition-api';
import HeaderComponent from '@/components/Common/ecosystems/Header.vue';
import { provideAuthenticationInfo } from '@/composables/store/Auth/useAuthenticationInfo';
import { provideLoginFormProgressCircular } from '@/composables/store/Auth/useLoginFormProgressCircular';

export default defineComponent({
    components: {
        Header: HeaderComponent,
    },
    setup() {
        // 全体利用するものはここで定義する
        provideAuthenticationInfo();
        provideLoginFormProgressCircular();
    },
});
