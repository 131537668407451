import { LoginSuccessResponse } from '@/models/interface/Auth/IAuthRepository';
import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAuthenticationInfo = () => {
    const initParam = {
        // ローカルストレージに存在する場合はログイン済の時のみ
        isAuthenticated: localStorage.getItem('isAuthenticated') !== null,
    };

    const isAuthenticated = ref<boolean>(initParam.isAuthenticated);
    const memberId = ref<number>(0);
    const memberName = ref<string>('');

    provide('isAuthenticated', isAuthenticated);
    provide('memberId', memberId);
    provide('memberName', memberName);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAuthenticationInfo = () => {
    const isAuthenticated = inject('isAuthenticated') as Ref<boolean>;
    const memberId = inject('memberId') as Ref<number>;
    const memberName = inject('memberName') as Ref<string>;

    const setAuthenticationInfo = (status: boolean, data: LoginSuccessResponse): void => {
        isAuthenticated.value = status;
        memberId.value = data.memberId;
        memberName.value = data.memberName;
        localStorage.setItem('isAuthenticated', 'true');
        //NOTE: 認証tokenはタブ毎に管理
        sessionStorage.setItem('token', data.token);
    };

    const initAuthenticationInfo = (): void => {
        isAuthenticated.value = false;
        memberId.value = 0;
        memberName.value = '';
        localStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('token');
    };

    const authenticatedUser = (): boolean => {
        return isAuthenticated.value;
    };

    return {
        isAuthenticated,
        memberId,
        memberName,
        setAuthenticationInfo,
        initAuthenticationInfo,
        authenticatedUser,
    };
};
