import { CreateSuccessResponse, DeleteSuccessResponse, FailedResponse } from '../common';

export interface IAnalysisPlanRepository {
    /**
     * 通話解析プランの登録
     */
    postAnalysisPlanCreation(
        name: string,
        time: number,
        additionalFee: number,
        standardPlanFlag: boolean,
        memo?: string,
    ): Promise<AnalysisPlanCreationResponse>;

    /**
     * 通話解析プランの削除
     *
     * @param planId
     */
    postAnalysisPlanDelete(planId: number): Promise<AnalysisPlanDeleteResponse>;

    /**
     * 通話解析プランの一覧取得
     */
    getAnalysisPlanIndex(): Promise<AnalysisPlanFetchResponse>;

    /**
     * 会社の通話解析プランの適用
     */
    postAnalysisPlanCompanyContract(
        companyId: number,
        planId: number,
        applyDate: string,
        startDate: string,
        memo?: string,
    ): Promise<AnalysisPlanContractResponse>;
}

export type AnalysisPlanCreationResponse = CreateSuccessResponse | FailedResponse;
export type AnalysisPlanDeleteResponse = DeleteSuccessResponse | FailedResponse;
export type AnalysisPlanContractResponse = CreateSuccessResponse | FailedResponse;
export type AnalysisPlanFetchResponse = AnalysisPlanResponse | FailedResponse;
export const CUSTOM_PLAN_KIND = 1;
export const STANDARD_PLAN_KIND = 2;

export type AnalysisPlanResponse = {
    status: boolean;
    data: {
        data: AnalysisPlanInfo[];
    };
};

export type AnalysisPlanInfo = {
    id: number;
    name: string;
    time: number;
    additionalFee: number;
    memo?: string;
    kind: number;
};
