import {
    ScheduleResponse,
    IReflectScheduleRepository,
    ReflectScheduleListResponse,
} from '@/models/interface/Management/IReflectScheduleRepository';
import { ScheduleType } from '@/types/schedule';
import { ReflectedStatus } from '@/types/tenant';
import axios from '@/plugins/axios';

export class ReflectScheduleRepository implements IReflectScheduleRepository {
    async implementSchedule(): Promise<ScheduleResponse> {
        return await axios.get('/api/management/schedule/manual-implement').catch(error => error.response || error);
    }

    async fetchScheduleList(status: ReflectedStatus): Promise<ReflectScheduleListResponse> {
        return await axios
            .get('/api/management/schedules', {
                params: {
                    status: status,
                },
            })
            .catch(error => error.response || error);
    }

    async cancelSchedule(scheduleId: number, type: ScheduleType): Promise<ScheduleResponse> {
        return await axios
            .delete(`/api/management/schedule/${type}/${scheduleId}`)
            .catch(error => error.response || error);
    }
}
