import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideLoginFormProgressCircular = () => {
    const isLoginProgressing = ref(false);

    provide('isLoginProgressing', isLoginProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useLoginFormProgressCircular = () => {
    const isLoginProgressing = inject('isLoginProgressing') as Ref<boolean>;

    const loginProgressStart = (): void => {
        isLoginProgressing.value = true;
    };

    const loginProgressEnd = (): void => {
        isLoginProgressing.value = false;
    };

    return {
        isLoginProgressing,
        loginProgressStart,
        loginProgressEnd,
    };
};
