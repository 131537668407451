import axios from '@/plugins/axios';
import {
    CallStatusFetchResponse,
    ITenantARepository,
    TenantSchemaFetchResponse,
} from '@/models/interface/EmotionAnalysis/ITenantARepository';

export class TenantARepository implements ITenantARepository {
    async getTenantSchemaIndex(): Promise<TenantSchemaFetchResponse> {
        return await axios.get('/api/tenant-schema/index').catch(error => error.response || error);
    }

    async getCallStatusIndex(tenantIdentifier: string): Promise<CallStatusFetchResponse> {
        return await axios
            .get('/api/call-status/index', { params: { tenantIdentifier: tenantIdentifier } })
            .catch(error => error.response || error);
    }
}
