import axios from '@/plugins/axios';
import {
    FetchExternalLoginTokenResponse,
    IAuthRepository,
    LoginRequestResponse,
} from '../../interface/Auth/IAuthRepository';

export class AuthRepository implements IAuthRepository {
    async postLogin(loginId: string, password: string): Promise<LoginRequestResponse> {
        return await axios
            .post('/api/login', {
                loginId: loginId,
                password: password,
            })
            .catch(error => error.response || error);
    }

    async getExternalLoginToken(
        systemId: number,
        tenantIdentifier: string,
        apiKey: string,
    ): Promise<FetchExternalLoginTokenResponse> {
        return await axios
            .post(`${process.env.VUE_APP_HOPE_API_URL}api/generate/token`, {
                systemId: systemId,
                userIdentifier: tenantIdentifier,
                apiKey: apiKey,
                opName: '簡易ログインユーザー', //NOTE: PFからのユーザー名を固定
            })
            .catch(error => error.response || error);
    }
}
