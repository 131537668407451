import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueCompositionAPI from '@vue/composition-api';
import vuetify from './plugins/vuetify';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { EmotionAnalysisRepository } from '@/models/repository/EmotionAnalysis/EmotionAnalysisRepository';
import { AuthRepository } from '@/models/repository/Auth/AuthRepository';
import { TenantARepository } from '@/models/repository/EmotionAnalysis/TenantARepository';
import { TenantMRepository } from '@/models/repository/Management/TenantMRepository';
import { AnalysisProgressRepository } from '@/models/repository/EmotionAnalysis/AnalysisProgressRepository';
import { CompanyRepository } from '@/models/repository/Management/CompanyRepository';
import { ReflectScheduleRepository } from '@/models/repository/Management/ReflectScheduleRepository';
import { StatsServerRepository } from '@/models/repository/Management/StatsServerRepository';
import { AnalysisPlanRepository } from './models/repository/Management/AnalysisPlanRepository';
import { PaymentRepository } from '@/models/repository/Management/PaymentRepository';

Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;

// 認証関連
container.register('IAuthRepository', { useClass: AuthRepository });

// 解析関連
container.register('IEmotionAnalysisRepository', { useClass: EmotionAnalysisRepository });
container.register('ITenantARepository', { useClass: TenantARepository });
container.register('IAnalysisProgressRepository', { useClass: AnalysisProgressRepository });

//通話解析プラン関連
container.register('IAnalysisPlanRepository', { useClass: AnalysisPlanRepository });

// テナント管理関連
container.register('ITenantMRepository', { useClass: TenantMRepository });
container.register('IStatsServerRepository', { useClass: StatsServerRepository });
container.register('ICompanyRepository', { useClass: CompanyRepository });

// 反映スケジュール関連
container.register('IReflectScheduleRepository', { useClass: ReflectScheduleRepository });

// 料金関係
container.register('IPaymentRepository', { useClass: PaymentRepository });
new Vue({
    router,
    vuetify,
    render: h => h(App),
    created: () => document.documentElement.setAttribute('lang', 'ja'),
}).$mount('#app');
