import { getCurrentInstance } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useRouter = () => {
    const instance = getCurrentInstance();

    if (!instance) {
        throw new Error(`Should be used in setup().`);
    }

    return instance.proxy.$router;
};
