import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(config => {
    if (sessionStorage.getItem('token') === null) return config;
    config.headers = {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        if (error.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/hope-platform/login';
        }
    },
);

export default axiosInstance;
